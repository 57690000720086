.custom-toast {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 4px;
  }
  
  .message {
    font-size: 14px;
    margin-bottom: 1rem;
  }
  
  .button-container-toast {
    display: flex;
    flex-direction: row;
  }
  
  .button-toast {
    font-size: 1rem;
    padding-left:0.9rem;
    padding-right:0.9rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .button-toast.yes {
    background-color: #32CD32;
    color: #fff;
  }
  
  .button-toast.no {
    background-color: #DC143C;
    color: #fff;
    margin-left: 1rem;
  }
  
  .button-toast.yes:hover, .button-toast.no:hover {
    transform: scale(1.05);
  }